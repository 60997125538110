import * as React from "react";
import Footer1 from "../components/footer-1";
import Header1 from "../components/header-1";
import { isLoggedIn } from "../services/auth";
import { useEffect } from "react";
import { withPrefix } from "gatsby";
import { navigate } from "gatsby";
import { useState } from "react";
import CropImageModal from "../components/CropImageModal";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { upscaleImage, resetUpscaleImage, selectCarouselsState } from "../sagas/carousels/carouselsSlice";
import { postImage, selectImageState } from "../sagas/image/imageSlice";

export default function Layout() {
  const dispatch = useDispatch();
  const { isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto } = useSelector(selectCarouselsState);
  const { isPostingImageSuccess, PostImageResponse } = useSelector(selectImageState);

  const [showAnimation, setShowAnimation] = React.useState("");
  const [showAnimationflag, setShowAnimationflag] = React.useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [cropper, setCropper] = useState(<any />);
  const [picture, setPicture] = useState("");

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      uploadCroppedImg(cropper.getCroppedCanvas().toDataURL());
      setModalOpen(false);
    }
  };
  const uploadCroppedImg = (base64data) => {
    var real_str = base64data.slice(22);
    setShowAnimation(withPrefix("assets/img/green-little-balls.gif"));
    setShowAnimationflag(true);

    dispatch({
      type: postImage.type,
      payload: { base64Url: real_str },
    });
  };
  useEffect(() => {
    if (isPostingImageSuccess) UpscaleImage(PostImageResponse.url);
  }, [isPostingImageSuccess, PostImageResponse])

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }
  }, []);

  const [selectedImage, setSelectedImage] = useState();
  async function UpscaleImage(image) {
    setSelectedImage(image);
    setShowAnimationflag(true);
    dispatch({
      type: upscaleImage.type,
      payload: { images: [image] }
    });
  }

  useEffect(() => {
    if (isUpscalingImageSuccess) {
      const merchifyArr = [];

      merchifyArr.push({
        id_val: 0,
        original: selectedImage,
        upscaled: UpscaleImageResponseDto.results[0].upscaled,
      });

      localStorage.setItem("mergify", JSON.stringify(merchifyArr));
      localStorage.setItem("job_id", ",,,,,");
      
      dispatch(resetUpscaleImage());
      navigate("/select-merch");
    } else if (isUpscalingImageFailed) {
      setShowAnimationflag(false);
      dispatch(resetUpscaleImage());
      //swal popup that says something went wrong and re-try message
      Swal.fire({
        icon: 'error',
        text: 'Oops! Something went wrong. Please try again.',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) UpscaleImage(selectedImage);
      })
    }
  }, [isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto])

  const runPipeline = (e) => {
    if (!e.target.files || e.target.files.length == 0) {
      return;
    }
    setModalOpen(true);
    const file = e.target.files[0];

    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = async function () {
      setPicture(reader.result);
    };
  };

  return (
    <>
      <div className="container-main" id="page">
        <Header1></Header1>
        {showAnimation && (
          <div className="new_gif" style={{ display: "flex" }}>
            <img
              src={showAnimation}
              style={{
                display: showAnimationflag ? "inline-block" : "none",
              }}
              className="img-fluid mw_50"
              alt="loading"
            />
          </div>
        )}
        <main className="content-main" style={{ marginLeft: "100px" }}>
          <div className="content-inner">
            <input
              type="file"
              id="image-input"
              onChange={runPipeline}
              accept=".jpeg, .png, .jpg, .webp, .heic"
            />
            <CropImageModal 
              cropModalOpen={modalOpen} 
              setCropModalOpen={setModalOpen} 
              picture={picture}
              cropper={cropper} 
              setCropper={setCropper}
              getCropData={getCropData}
            />
          </div>
        </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
